import * as yup from "yup";
import { Formik } from "formik";
import { useState } from "react";
// import { DateRange } from "react-date-range";
import { Button, Row, Col, Card } from "react-bootstrap";
import axios from 'axios';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datetime/css/react-datetime.css';
import BASE_URL from "config";

const serviceFormSchema = yup.object().shape({
  name: yup.string().required("Service name is required"),
  description: yup.string(),
  price: yup.number().typeError("You must specify a number").min(0, "Price cannot be negative"),
});

export default function ServiceForm({ service, onSave, onCancel }) {
  const [state, setState] = useState({
    name: service?.name || "",
    description: service?.description || "",
    price: service?.price || 0,
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("Form submitted with values:", values);
    const requestBody = {
      name: values.name,
      description: values.description,
      price: values.price,
    };

    console.log("Request body:", requestBody);

    try {
      if (service) {
        console.log(`Updating service with ID: ${service._id}`);
        await axios.patch(`${BASE_URL}/api/service/${service._id}`, requestBody);
        toast.success("Service updated successfully");
      } else {
        console.log("Creating new service");
        await axios.post(`${BASE_URL}/api/service`, requestBody);
        toast.success("Service added successfully");
      }
      onSave();
    } catch (error) {
      console.error("There was an error saving the service!", error.response ? error.response.data : error.message);
      toast.error("Failed to save service");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>

    <Row>
      <Col md={12}>
        <Card body className="mb-4">
          <div className="card-title mb-3">{service ? "Edit Service" : "Add a Service"}</div>
          <Formik
            initialValues={state}
            onSubmit={handleSubmit}
            validationSchema={serviceFormSchema}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 form-group mb-3">
                    <label htmlFor="name">Service Name</label>
                    <input
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Enter the service's name"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <div className="text-danger mt-1 ml-2">{errors.name}</div>
                    )}
                  </div>

                  <div className="col-md-6 form-group mb-3">
                    <label htmlFor="description">Description</label>
                    <input
                      id="description"
                      name="description"
                      className="form-control"
                      placeholder="Enter a description"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    />
                    {errors.description && touched.description && (
                      <div className="text-danger mt-1 ml-2">{errors.description}</div>
                    )}
                  </div>

                  {/* <div className="col-md-6 form-group mb-3">
                    <label htmlFor="price">Price</label>
                    <input
                      id="price"
                      name="price"
                      className="form-control"
                      placeholder="Enter the price"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.price}
                    />
                    {errors.price && touched.price && (
                      <div className="text-danger mt-1 ml-2">{errors.price}</div>
                    )}
                  </div> */}

                  <div className="col-md-12">
                    <Button type="submit" disabled={isSubmitting}>
                      {service ? "Update" : "Submit"}
                    </Button> &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="secondary" onClick={onCancel}>Cancel</Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Card>
      </Col>
    </Row>
          <ToastContainer />

    </>
  );
}