import axios from "axios";
import localStorageService from "./localStorageService";
import BASE_URL from "config";

class JwtAuthService {


async register(username, email, password) {
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/register`, { username, email, password }, { withCredentials: true });
      const { token, user } = response.data;

      this.setUser(user);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Error registering");
    }
  }
  async loginWithEmailAndPassword({ email, password }) {
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/login`, { email, password }, { withCredentials: true });
      const { token, user, message } = response.data;

      this.setUser(user);
      return { token, user, message };
    } catch (error) {
      throw new Error(error.response?.data?.message || "Error logging in");
    }
  }

  async loginWithToken() {
    const data = await new Promise((resolve) => {
      setTimeout(() => resolve(this.user), 1000);
    });

    this.setSession(data.token);
    this.setUser(data);
    return data;
  }

  logout() {
    this.setSession(null);
    this.removeUser();
  }

  setSession(token) {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  }

  setUser(user) {
    localStorageService.setItem("auth_user", user);
  }

  removeUser() {
    localStorage.removeItem("auth_user");
  }

  async requestPasswordReset(email) {
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/forgot-password`, { email });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Error requesting password reset");
    }
  }

  async resetPassword(userId, token, newPassword) {
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/reset-password`, { userId, token, password: newPassword });
      const { token: newToken } = response.data;

      this.setSession(newToken);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Error resetting password");
    }
  }

}

const service = new JwtAuthService();
export default service;
