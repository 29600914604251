import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Row, Col, Modal, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import Breadcrumb from "app/components/Breadcrumb";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from 'config';
export default function StationList({ onAdd }) {
  const [admins, setAdmins] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [adminToDelete, setAdminToDelete] = useState(null); 
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const pageSizes = [5, 10, 20];

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/auth/users`);
      setAdmins(response.data);
    } catch (error) {
      console.error("There was an error fetching the stations!", error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${BASE_URL}/api/auth/users/delete`, {
        data: { email: adminToDelete }
      });
      toast.success("Admin deleted successfully");
      fetchAdmins();
    } catch (error) {
      console.error("There was an error deleting the admin!", error.response ? error.response.data : error.message);
      toast.error("Failed to delete admin");
    } finally {
      setShowModal(false);
    }
  };

  const openModal = (adminEmail) => {
    setAdminToDelete(adminEmail);
    setShowModal(true);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); 
  };

  const totalPages = Math.ceil(admins.length / pageSize);
  const displayedAdmins = admins.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <section>
      <Breadcrumb routeSegments={[{ name: "Admins", path: "/admins" }]} />
      <Row>
        <Col md={12} className="mb-3">
          <Button onClick={onAdd}>Add Admin</Button>
        </Col>
        <Col md={12}>
          <Card body>
            <div className="card-title mb-3">Admin List</div>
            <Dropdown onSelect={handlePageSizeChange} className="mb-3">
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                Page Size: {pageSize}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {pageSizes.map(size => (
                  <Dropdown.Item key={size} eventKey={size}>{size}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <div style={{ overflowX: 'auto' }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Manager Name</th>
                    <th>Manager Email</th>
                    <th>Joining Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedAdmins.map((admin) => (
                    <tr key={admin._id}>
                      <td>{admin.username}</td>
                      <td>{admin.email}</td>
                      <td>{new Date(admin.createdAt).toLocaleDateString()}</td>
                      <td>
                      <Button variant="danger" onClick={() => openModal(admin.email)}>Delete</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Button
                variant="secondary"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <span>Page {currentPage} of {totalPages}</span>
              <Button
                variant="secondary"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      <ToastContainer />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this admin?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}
