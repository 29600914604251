import { lazy } from "react";
import StationManagement from "./StationManagement";
import ReservationManagement from "./ReservationManagement";
import BlogManagement from "./BlogManagement";
import NewsletterManagement from "./NewsletterManagement";
import SupportManagement from "./SupportManagement";
import AdminManagement from "./AdminManagement";
import ServiceManagement from "./ServiceManagement";

const TagInput = lazy(() => import("./TagInput"));
//const StationManagement = lazy(() => import("./StationManagement"));
const LayoutForm = lazy(() => import("./LayoutForm"));
const FormEditor = lazy(() => import("./FormEditor"));
const FormsWizard = lazy(() => import("./FormsWizard"));
const ActionBarForm = lazy(() => import("./ActionBarForm"));
const InputGroupForm = lazy(() => import("./InputGroupForm"));
const FormValidation = lazy(() => import("./FormValidation"));
const MultiColumnForms = lazy(() => import("./MultiColumnForms"));

const formsRoutes = [
  { path: "/services", element: <ServiceManagement /> },
  { path: "/stations", element: <StationManagement /> },
  { path: "/admins", element: <AdminManagement /> },
  { path: "/support", element: <SupportManagement /> },
  { path: "/blogs", element: <BlogManagement /> },
  { path: "/newsletters", element: <NewsletterManagement /> },
  { path: "/reservations", element: <ReservationManagement /> },
  { path: "/forms/action-bar", element: <ActionBarForm /> },
  { path: "/forms/layouts", element: <LayoutForm /> },
  { path: "/forms/multi-column-forms", element: <MultiColumnForms /> },
  { path: "/forms/input-group", element: <InputGroupForm /> },
  { path: "/forms/form-validation", element: <FormValidation /> },
  { path: "/forms/forms-wizard", element: <FormsWizard /> },
  { path: "/forms/form-editor", element: <FormEditor /> },
  { path: "/forms/tag-input", element: <TagInput /> }
];

export default formsRoutes;
