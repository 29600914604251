import React from 'react';
import SupportForm from './SupportForm';

export default function SupportManagement() {
  return (
    <div>
      <SupportForm />
    </div>
  );
}
