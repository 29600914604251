import React from 'react';
import NewsletterList from './NewsletterList';

export default function NewsletterManagement() {
  return (
    <div>
      <NewsletterList />
    </div>
  );
}
