
import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Row, Col, Modal, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import Breadcrumb from "app/components/Breadcrumb";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from 'config';

export default function ServiceList({ onAdd, onEdit }) {
  const [services, setServices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const pageSizes = [5, 10, 20];

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/service`);
      setServices(response.data);
    } catch (error) {
      console.error("There was an error fetching the services!", error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${BASE_URL}/api/service/${serviceToDelete}`);
      toast.success("Service deleted successfully");
      fetchServices();
    } catch (error) {
      console.error("There was an error deleting the service!", error.response ? error.response.data : error.message);
      toast.error("Failed to delete service");
    } finally {
      setShowModal(false);
    }
  };

  const openModal = (serviceId) => {
    setServiceToDelete(serviceId);
    setShowModal(true);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); 
  };

  const totalPages = Math.ceil(services.length / pageSize);
  const displayedServices = services.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <section>
      <Breadcrumb routeSegments={[{ name: "Services", path: "/services" }]} />
      <Row>
        <Col md={12} className="mb-3">
          <Button onClick={onAdd}>Add Service</Button>
        </Col>
        <Col md={12}>
          <Card body>
            <div className="card-title mb-3">Service List</div>
            <Dropdown onSelect={handlePageSizeChange} className="mb-3">
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                Page Size: {pageSize}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {pageSizes.map(size => (
                  <Dropdown.Item key={size} eventKey={size}>{size}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <div style={{ overflowX: 'auto' }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    {/* <th>Price</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedServices.map((service) => (
                    <tr key={service._id}>
                      <td>{service.name}</td>
                      <td>{service.description || 'No description available'}</td>
                      {/* <td>${service.price}</td> */}
                      <td>
                        <Button variant="success" onClick={() => onEdit(service)}>Edit</Button> &nbsp;&nbsp;
                        <Button variant="danger" onClick={() => openModal(service._id)}>Delete</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Button
                variant="secondary"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <span>Page {currentPage} of {totalPages}</span>
              <Button
                variant="secondary"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      <ToastContainer />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this service?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}