// import * as yup from "yup";
// import { Formik } from "formik";
// import { useState } from "react";
// import { Button, Row, Col, Card } from "react-bootstrap";
// import axios from 'axios';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import BASE_URL from 'config';

// const blogPostSchema = yup.object().shape({
//   title: yup.string().required("Title is required"),
//   mainImage: yup.string().required("Main image URL is required"),
//   sections: yup.array().of(
//     yup.object().shape({
//       title: yup.string(),
//       content: yup.string(),
//     })
//   ),
// });

// export default function BlogForm({ blog, onSave, onCancel }) {
//   const [sections, setSections] = useState(blog?.sections || [{ title: "", content: "" }]);

//   const addSection = (setFieldValue, values) => {
//     const newSections = [...values.sections, { title: "", content: "" }];
//     setSections(newSections);
//     setFieldValue("sections", newSections);
//   };

//   const removeSection = (index, setFieldValue, values) => {
//     const newSections = values.sections.filter((_, i) => i !== index);
//     setSections(newSections);
//     setFieldValue("sections", newSections);
//   };

//   const handleSubmit = async (values, { setSubmitting }) => {
//     try {
//       const requestBody = {
//         title: values.title,
//         mainImage: values.mainImage,
//         sections: values.sections,
//       };
//       const endpoint = blog ? ${BASE_URL}/api/blogs/${blog._id} : ${BASE_URL}/api/blogs;
//       const result = blog ? await axios.put(endpoint, requestBody) : await axios.post(endpoint, requestBody);
//       toast.success(Blog ${blog ? 'updated' : 'added'} successfully);
//       onSave();
//     } catch (error) {
//       const errorMessage = error.response?.data?.message || error.message;
//       console.error("Error saving blog:", errorMessage);
//       toast.error("There was an error saving the blog: " + errorMessage);
//     } finally {
//       setSubmitting(false);
//     }
//   };

//   return (
//     <>
//       <Row>
//         <Col md={12}>
//           <Card body className="mb-4">
//             <div className="card-title mb-3">{blog ? "Edit Blog" : "Add a Blog"}</div>
//             <Formik
//               initialValues={{
//                 title: blog?.title || "",
//                 mainImage: blog?.mainImage || "",
//                 sections: sections
//               }}
//               onSubmit={handleSubmit}
//               validationSchema={blogPostSchema}>
//               {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isSubmitting, setFieldValue }) => (
//                 <form onSubmit={handleSubmit}>
//                   <div className="row">
//                     <div className="col-md-6 form-group mb-3">
//                       <label htmlFor="title">Blog Title</label>
//                       <input
//                         id="title"
//                         name="title"
//                         type="text"
//                         className="form-control"
//                         placeholder="Enter blog title"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         value={values.title}
//                       />
//                       {errors.title && touched.title && <div className="text-danger mt-1 ml-2">{errors.title}</div>}
//                     </div>

//                     <div className="col-md-6 form-group mb-3">
//                       <label htmlFor="mainImage">Main Image URL</label>
//                       <input
//                         id="mainImage"
//                         name="mainImage"
//                         type="text"
//                         className="form-control"
//                         placeholder="Enter URL of the main image"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         value={values.mainImage}
//                       />
//                       {errors.mainImage && touched.mainImage && <div className="text-danger mt-1 ml-2">{errors.mainImage}</div>}
//                     </div>

//                     {values.sections.map((section, index) => (
//                       <div key={index} className="col-md-12 form-group mb-3">
//                         <label htmlFor={sections[${index}].title}>Section Title</label>
//                         <input
//                           id={sections[${index}].title}
//                           name={sections[${index}].title}
//                           type="text"
//                           className="form-control"
//                           placeholder={Enter section ${index + 1} title}
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={section.title}
//                         />
//                         <label htmlFor={sections[${index}].content}>Content</label>
//                         <textarea
//                           id={sections[${index}].content}
//                           name={sections[${index}].content}
//                           className="form-control"
//                           placeholder={Enter section ${index + 1} content}
//                           rows="5"
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={section.content}
//                         />
//                         {sections.length > 1 && (
//                           <Button variant="danger" onClick={() => removeSection(index, setFieldValue, values)} className="mt-2">
//                             Remove Section
//                           </Button>
//                         )}
//                       </div>
//                     ))}



//                     <div className="mt-3">
//                       <Button type="submit" disabled={isSubmitting}>
//                         {blog ? "Update" : "Submit"}
//                       </Button> &nbsp;&nbsp;&nbsp;&nbsp;
//                       <Button variant="primary" onClick={() => addSection(setFieldValue, values)}>
//                       Add Another Section
//                     </Button>  &nbsp;&nbsp;&nbsp;&nbsp;
//                       <Button variant="secondary" onClick={onCancel}>Cancel</Button>
//                     </div>
//                   </div>
//                 </form>
//               )}
//             </Formik>
//           </Card>
//         </Col>
//       </Row>
//       <ToastContainer />
//     </>
//   );
// }
import * as yup from "yup";
import { Formik } from "formik";
import { useState } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from 'config';

// Validation schema
const blogPostSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  mainImage: yup.mixed().nullable(),
  imageDescription: yup.string().nullable(),
  sections: yup.array().of(
    yup.object().shape({
      title: yup.string(),
      content: yup.string(),
    })
  ),
  customUpdatedAt: yup.date().required("Date is required"), // Validation for the custom date field
});

// Blog form component
export default function BlogForm({ blog, onSave, onCancel }) {
  const [sections, setSections] = useState(blog?.sections || [{ title: "", content: "" }]);
  const [imageFile, setImageFile] = useState(null);

  const addSection = (setFieldValue, values) => {
    const newSections = [...values.sections, { title: "", content: "" }];
    setSections(newSections);
    setFieldValue("sections", newSections);
  };

  const removeSection = (index, setFieldValue, values) => {
    const newSections = values.sections.filter((_, i) => i !== index);
    setSections(newSections);
    setFieldValue("sections", newSections);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error("File size exceeds 5MB");
        return;
      }
      setImageFile(file);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append("title", values.title);
      if (imageFile) {
        formData.append("mainImage", imageFile);
      }
      formData.append("imageDescription", values.imageDescription); 
      formData.append("sections", JSON.stringify(values.sections));
      formData.append("customUpdatedAt", values.customUpdatedAt); // Append the custom date
      console.log(formData.values)
      const endpoint = blog ? `${BASE_URL}/api/blogs/${blog._id}` : `${BASE_URL}/api/blogs`;
      const result = blog
        ? await axios.put(endpoint, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        : await axios.post(endpoint, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

      toast.success(`Blog ${blog ? 'updated' : 'added'} successfully`);
      onSave();
    } catch (error) {
      console.error("Error saving blog:", error);
      toast.error("There was an error saving the blog: " + error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Card body className="mb-4">
            <div className="card-title mb-3">{blog ? "Edit Blog" : "Add a Blog"}</div>
            <Formik
              initialValues={{
                title: blog?.title || "",
                sections: sections,
                customUpdatedAt: blog?.customUpdatedAt || "", // Initial value for the custom date
                imageDescription: blog?.imageDescription || "",
              }}
              onSubmit={handleSubmit}
              validationSchema={blogPostSchema}
            >
              {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isSubmitting, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 form-group mb-3">
                      <label htmlFor="title">Blog Title</label>
                      <input
                        id="title"
                        name="title"
                        type="text"
                        className="form-control"
                        placeholder="Enter blog title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />
                      {errors.title && touched.title && <div className="text-danger mt-1 ml-2">{errors.title}</div>}
                    </div>

                    <div className="col-md-6 form-group mb-3">
                      <label htmlFor="mainImage">Main Image</label>
                      <input
                        id="mainImage"
                        name="mainImage"
                        type="file"
                        className="form-control"
                        onChange={handleImageChange}
                      />
                      {errors.mainImage && touched.mainImage && <div className="text-danger mt-1 ml-2">{errors.mainImage}</div>}
                    </div>

                    <div className="col-md-6 form-group mb-3">
                      <label htmlFor="customUpdatedAt">Publication Date</label>
                      <input
                        id="customUpdatedAt"
                        name="customUpdatedAt"
                        type="date"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.customUpdatedAt}
                      />
                      {errors.customUpdatedAt && touched.customUpdatedAt && <div className="text-danger mt-1 ml-2">{errors.customUpdatedAt}</div>}
                    </div>
                    <div className="col-md-6 form-group mb-3">
                      <label htmlFor="imageDescription">Image Description</label>
                      <input
                        id="imageDescription"
                        name="imageDescription"
                        type="text"
                        className="form-control"
                        placeholder="Enter image description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.imageDescription}
                      />
                      {errors.imageDescription && touched.imageDescription && (
                        <div className="text-danger mt-1 ml-2">{errors.imageDescription}</div>
                      )}
                    </div>


                    {values.sections.map((section, index) => (
                      <div key={index} className="col-md-12 form-group mb-3">
                        <label htmlFor={`section-title-${index}`}>Section Title</label>
                        <input
                          id={`section-title-${index}`}
                          name={`sections[${index}].title`}
                          type="text"
                          className="form-control"
                          placeholder={`Enter section ${index + 1} title`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={section.title}
                        />
                        <label htmlFor={`section-content-${index}`}>Content</label>
                        <textarea
                          id={`section-content-${index}`}
                          name={`sections[${index}].content`}
                          className="form-control"
                          placeholder={`Enter section ${index + 1} content`}
                          rows="5"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={section.content}
                        />
                        {sections.length > 1 && (
                          <Button variant="danger" onClick={() => removeSection(index, setFieldValue, values)} className="mt-2">
                            Remove Section
                          </Button>
                        )}
                      </div>
                    ))}

                    <div className="mt-3">
                      <Button type="submit" disabled={isSubmitting}>
                        {blog ? "Update" : "Submit"}
                      </Button> &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button variant="primary" onClick={() => addSection(setFieldValue, values)}>
                        Add Another Section
                      </Button> &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button variant="secondary" onClick={onCancel}>Cancel</Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </>
  );
}
