// // import * as yup from "yup";
// // import { Formik } from "formik";
// // import { useState } from "react";
// // import { DateRange } from "react-date-range";
// // import { Button, Row, Col, Card } from "react-bootstrap";
// // import Breadcrumb from "app/components/Breadcrumb";
// // import "react-date-range/dist/styles.css";
// // import "react-date-range/dist/theme/default.css";
// // import axios from 'axios';

// // export default function FormBasic() {
// //   const [state, setState] = useState({
// //     managerName: "",
// //     email: "",
// //     phoneNumber: "",
// //     stationName: "",
// //     city: "",
// //     address: "",
// //     capacity: "1",
// //     timeLapse: {
// //       startDate: new Date(),
// //       endDate: (() => {
// //         let date = new Date();
// //         date.setDate(date.getDate() + 7);
// //         return date;
// //       })()
// //     }
// //   });

// //   const cities = [
// //     "Casablanca", "Rabat", "Fes", "Marrakech", "Agadir", 
// //     "Tangier", "Meknes", "Oujda", "Kenitra", "Tetouan",
// //     // Add more cities as needed
// //   ];

// //   const handleSubmit = async (values, { setSubmitting }) => {
// //     try {
// //         // Prepare request body
// //         const requestBody = {
// //             managerName: values.managerName,
// //             email: values.email,
// //             phoneNumber: values.phoneNumber,
// //             stationName: values.stationName,
// //             city: values.city,
// //             address: values.address,
// //             capacity: Number(values.capacity), // Ensure this is a number
// //             timeLapse: Number(values.timeLapse.startDate) // Convert as needed
// //         };

// //         // Send POST request
// //         await axios.post('http://localhost:5000/api/stations', requestBody);
// //         alert("Station added successfully");
// //     } catch (error) {
// //         console.error("There was an error adding the station!", error.response ? error.response.data : error.message);
// //         alert("Failed to add station");
// //     } finally {
// //         setSubmitting(false);
// //     }
// // };


// //   const handleDateRangeChange = (ranges) => {
// //     const { selection } = ranges;
// //     setState((prevState) => ({
// //       ...prevState,
// //       timeLapse: { startDate: selection.startDate, endDate: selection.endDate }
// //     }));
// //   };

// //   return (
// //     <section>
// //       <Breadcrumb routeSegments={[{ name: "Forms", path: "/forms" }, { name: "Basic Form" }]} />

// //       <Row>
// //         <Col md={12}>
// //           <Card body className="mb-4">
// //             <div className="card-title mb-3">Add a Station</div>

// //             <Formik
// //               initialValues={state}
// //               onSubmit={handleSubmit}
// //               validationSchema={basicFormSchema}>
// //               {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
// //                 <form onSubmit={handleSubmit}>
// //                   <div className="row">
// //                     <div className="col-md-6 form-group mb-3">
// //                       <label htmlFor="managerName">Manager Name</label>
// //                       <input
// //                         id="managerName"
// //                         name="managerName"
// //                         className="form-control"
// //                         placeholder="Enter your name"
// //                         type="text"
// //                         onChange={handleChange}
// //                         onBlur={handleBlur}
// //                         value={values.managerName}
// //                       />
// //                       {errors.managerName && touched.managerName && (
// //                         <div className="text-danger mt-1 ml-2">{errors.managerName}</div>
// //                       )}
// //                     </div>

// //                     <div className="col-md-6 form-group mb-3">
// //                       <label htmlFor="stationName">Station Name</label>
// //                       <input
// //                         id="stationName"
// //                         name="stationName"
// //                         className="form-control"
// //                         placeholder="Enter the station's name"
// //                         type="text"
// //                         onChange={handleChange}
// //                         onBlur={handleBlur}
// //                         value={values.stationName}
// //                       />
// //                       {errors.stationName && touched.stationName && (
// //                         <div className="text-danger mt-1 ml-2">{errors.stationName}</div>
// //                       )}
// //                     </div>

// //                     <div className="col-md-6 form-group mb-3">
// //                       <label htmlFor="email">Email address</label>
// //                       <input
// //                         name="email"
// //                         id="email"
// //                         className="form-control"
// //                         placeholder="exemple@xmail.com"
// //                         type="email"
// //                         onChange={handleChange}
// //                         onBlur={handleBlur}
// //                         value={values.email}
// //                       />
// //                       {errors.email && touched.email && (
// //                         <div className="text-danger mt-1 ml-2">{errors.email}</div>
// //                       )}
// //                       <small id="emailHelp" className="form-text text-muted">
// //                         We'll never share your email with anyone else.
// //                       </small>
// //                     </div>

// //                     <div className="col-md-6 form-group mb-3">
// //                       <label htmlFor="phoneNumber">Phone</label>
// //                       <input
// //                         className="form-control"
// //                         id="phoneNumber"
// //                         name="phoneNumber"
// //                         placeholder="Enter your phone"
// //                         type="text"
// //                         onChange={handleChange}
// //                         onBlur={handleBlur}
// //                         value={values.phoneNumber}
// //                       />
// //                       {errors.phoneNumber && touched.phoneNumber && (
// //                         <div className="text-danger mt-1 ml-2">{errors.phoneNumber}</div>
// //                       )}
// //                     </div>

// //                     <div className="col-md-6 form-group mb-3">
// //                       <label htmlFor="capacity">Capacity of the station</label>
// //                       <input
// //                         className="form-control"
// //                         id="capacity"
// //                         name="capacity"
// //                         placeholder="1"
// //                         type="number"
// //                         onChange={handleChange}
// //                         onBlur={handleBlur}
// //                         value={values.capacity}
// //                       />
// //                       {errors.capacity && touched.capacity && (
// //                         <div className="text-danger mt-1 ml-2">{errors.capacity}</div>
// //                       )}
// //                     </div>

// //                     <div className="col-md-6 form-group mb-3">
// //                       <label htmlFor="city">City</label>
// //                       <select
// //                         id="city"
// //                         className="form-control"
// //                         name="city"
// //                         value={values.city}
// //                         onChange={handleChange}
// //                         onBlur={handleBlur}>
// //                         <option value="">Select a city</option>
// //                         {cities.map((city, index) => (
// //                           <option key={index} value={city}>{city}</option>
// //                         ))}
// //                       </select>
// //                       {errors.city && touched.city && (
// //                         <div className="text-danger mt-1 ml-2">{errors.city}</div>
// //                       )}
// //                     </div>

// //                     <div className="col-md-12 form-group mb-3">
// //                       <label htmlFor="address">Address</label>
// //                       <input
// //                         className="form-control"
// //                         id="address"
// //                         name="address"
// //                         placeholder="Enter address"
// //                         type="text"
// //                         onChange={handleChange}
// //                         onBlur={handleBlur}
// //                         value={values.address}
// //                       />
// //                       {errors.address && touched.address && (
// //                         <div className="text-danger mt-1 ml-2">{errors.address}</div>
// //                       )}
// //                     </div>

// //                     <Col xs={12} className="form-group mb-3">
// //                       <label htmlFor="picker2">TimeLapse</label>
// //                       <DateRange
// //                         ranges={[{
// //                           startDate: new Date(state.timeLapse.startDate),
// //                           endDate: new Date(state.timeLapse.endDate),
// //                           key: 'selection'
// //                         }]}
// //                         onChange={handleDateRangeChange}
// //                       />
// //                     </Col>

// //                     <Col>
// //                       <Button type="submit" disabled={isSubmitting}>Submit</Button>
// //                     </Col>
// //                   </div>
// //                 </form>
// //               )}
// //             </Formik>
// //           </Card>
// //         </Col>
// //       </Row>
// //     </section>
// //   );
// // }

// // const basicFormSchema = yup.object().shape({
// //   managerName: yup.string().required("Manager name is required"),
// //   stationName: yup.string().required("Station name is required"),
// //   phoneNumber: yup.string().required("Phone Number is required"),
// //   city: yup.string().required("City is required"),
// //   address: yup.string().required("Address is required"),
// //   capacity: yup.number().min(1, "Capacity must be at least 1").required("Capacity is required"),
// //   email: yup.string().email("Invalid email").required("Email is required"),
// // });
// import * as yup from "yup";
// import { Formik } from "formik";
// import { useState, useEffect } from "react";
// import { DateRange } from "react-date-range";
// import { Button, Row, Col, Card, Table } from "react-bootstrap";
// import Breadcrumb from "app/components/Breadcrumb";
// import "react-date-range/dist/styles.css";
// import "react-date-range/dist/theme/default.css";
// import axios from 'axios';

// // Validation Schema
// const basicFormSchema = yup.object().shape({
//   managerName: yup.string().required("Manager name is required"),
//   stationName: yup.string().required("Station name is required"),
//   phoneNumber: yup.string().required("Phone Number is required"),
//   city: yup.string().required("City is required"),
//   address: yup.string().required("Address is required"),
//   capacity: yup.number().min(1, "Capacity must be at least 1").required("Capacity is required"),
//   email: yup.string().email("Invalid email").required("Email is required"),
// });

// export default function StationManager() {
//   const [stations, setStations] = useState([]);
//   const [showForm, setShowForm] = useState(false);
//   const [selectedStation, setSelectedStation] = useState(null);
//   const [formState, setFormState] = useState({
//     managerName: "",
//     email: "",
//     phoneNumber: "",
//     stationName: "",
//     city: "",
//     address: "",
//     capacity: "1",
//     timeLapse: {
//       startDate: new Date(),
//       endDate: (() => {
//         let date = new Date();
//         date.setDate(date.getDate() + 7);
//         return date;
//       })()
//     }
//   });

//   useEffect(() => {
//     fetchStations();
//   }, []);

//   const fetchStations = async () => {
//     try {
//       const response = await axios.get('http://localhost:5000/api/stations/all');
//       setStations(response.data);
//     } catch (error) {
//       console.error("There was an error fetching the stations!", error);
//     }
//   };

//   const handleDateRangeChange = (ranges) => {
//     const { selection } = ranges;
//     setFormState((prevState) => ({
//       ...prevState,
//       timeLapse: { startDate: selection.startDate, endDate: selection.endDate }
//     }));
//   };

//   const handleSubmit = async (values, { setSubmitting }) => {
//     try {
//       const startDate = new Date(values.timeLapse.startDate);
//       const endDate = new Date(values.timeLapse.endDate);
//       const timeLapse = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

//       const requestBody = {
//         managerName: values.managerName,
//         email: values.email,
//         phoneNumber: values.phoneNumber,
//         stationName: values.stationName,
//         city: values.city,
//         address: values.address,
//         capacity: Number(values.capacity),
//         timeLapse: timeLapse
//       };

//       if (selectedStation) {
//         // Update station
//         await axios.patch(`http://localhost:5000/api/stations/${selectedStation._id}`, requestBody);
//       } else {
//         // Add new station
//         await axios.post('http://localhost:5000/api/stations', requestBody);
//       }

//       alert("Station saved successfully");
//       fetchStations(); // Refresh the list
//       setShowForm(false); // Hide form after submission
//       setSelectedStation(null); // Clear selected station
//     } catch (error) {
//       console.error("There was an error saving the station!", error.response ? error.response.data : error.message);
//       alert("Failed to save station");
//     } finally {
//       setSubmitting(false);
//     }
//   };

//   const handleEdit = (station) => {
//     setFormState({
//       ...station,
//       timeLapse: {
//         startDate: new Date(station.timeLapse.startDate),
//         endDate: new Date(station.timeLapse.endDate),
//       }
//     });
//     setSelectedStation(station);
//     setShowForm(true);
//   };

//   const handleDelete = async (stationId) => {
//     try {
//       await axios.delete(`http://localhost:5000/api/stations/${stationId}`);
//       alert("Station deleted successfully");
//       fetchStations(); // Refresh the list
//     } catch (error) {
//       console.error("There was an error deleting the station!", error.response ? error.response.data : error.message);
//       alert("Failed to delete station");
//     }
//   };

//   const cities = [
//     "Casablanca", "Rabat", "Fes", "Marrakech", "Agadir",
//     "Tangier", "Meknes", "Oujda", "Kenitra", "Tetouan",
//     // Add more cities as needed
//   ];

//   return (
//     <section>
//       <Breadcrumb routeSegments={[{ name: "Stations", path: "/stations" }]} />

//       <Row>
//         <Col md={12} className="mb-3">
//           <Button onClick={() => setShowForm(!showForm)}>
//             {showForm ? "Hide Form" : "Add Station"}
//           </Button>
//         </Col>

//         {showForm && (
//           <Col md={12}>
//             <Card body className="mb-4">
//               <div className="card-title mb-3">{selectedStation ? "Edit Station" : "Add a Station"}</div>

//               <Formik
//                 initialValues={formState}
//                 onSubmit={handleSubmit}
//                 validationSchema={basicFormSchema}>
//                 {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
//                   <form onSubmit={handleSubmit}>
//                     <div className="row">
//                       <div className="col-md-6 form-group mb-3">
//                         <label htmlFor="managerName">Manager Name</label>
//                         <input
//                           id="managerName"
//                           name="managerName"
//                           className="form-control"
//                           placeholder="Enter your name"
//                           type="text"
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={values.managerName}
//                         />
//                         {errors.managerName && touched.managerName && (
//                           <div className="text-danger mt-1 ml-2">{errors.managerName}</div>
//                         )}
//                       </div>

//                       <div className="col-md-6 form-group mb-3">
//                         <label htmlFor="stationName">Station Name</label>
//                         <input
//                           id="stationName"
//                           name="stationName"
//                           className="form-control"
//                           placeholder="Enter the station's name"
//                           type="text"
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={values.stationName}
//                         />
//                         {errors.stationName && touched.stationName && (
//                           <div className="text-danger mt-1 ml-2">{errors.stationName}</div>
//                         )}
//                       </div>

//                       <div className="col-md-6 form-group mb-3">
//                         <label htmlFor="email">Email address</label>
//                         <input
//                           name="email"
//                           id="email"
//                           className="form-control"
//                           placeholder="exemple@xmail.com"
//                           type="email"
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={values.email}
//                         />
//                         {errors.email && touched.email && (
//                           <div className="text-danger mt-1 ml-2">{errors.email}</div>
//                         )}
//                         <small id="emailHelp" className="form-text text-muted">
//                           We'll never share your email with anyone else.
//                         </small>
//                       </div>

//                       <div className="col-md-6 form-group mb-3">
//                         <label htmlFor="phoneNumber">Phone</label>
//                         <input
//                           className="form-control"
//                           id="phoneNumber"
//                           name="phoneNumber"
//                           placeholder="Enter your phone"
//                           type="text"
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={values.phoneNumber}
//                         />
//                         {errors.phoneNumber && touched.phoneNumber && (
//                           <div className="text-danger mt-1 ml-2">{errors.phoneNumber}</div>
//                         )}
//                       </div>

//                       <div className="col-md-6 form-group mb-3">
//                         <label htmlFor="capacity">Capacity of the station</label>
//                         <input
//                           className="form-control"
//                           id="capacity"
//                           name="capacity"
//                           placeholder="1"
//                           type="number"
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={values.capacity}
//                         />
//                         {errors.capacity && touched.capacity && (
//                           <div className="text-danger mt-1 ml-2">{errors.capacity}</div>
//                         )}
//                       </div>

//                       <div className="col-md-6 form-group mb-3">
//                         <label htmlFor="city">City</label>
//                         <select
//                           id="city"
//                           className="form-control"
//                           name="city"
//                           value={values.city}
//                           onChange={handleChange}
//                           onBlur={handleBlur}>
//                           <option value="">Select a city</option>
//                           {cities.map((city, index) => (
//                             <option key={index} value={city}>{city}</option>
//                           ))}
//                         </select>
//                         {errors.city && touched.city && (
//                           <div className="text-danger mt-1 ml-2">{errors.city}</div>
//                         )}
//                       </div>

//                       <div className="col-md-12 form-group mb-3">
//                         <label htmlFor="address">Address</label>
//                         <input
//                           className="form-control"
//                           id="address"
//                           name="address"
//                           placeholder="1234 Main St"
//                           type="text"
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={values.address}
//                         />
//                         {errors.address && touched.address && (
//                           <div className="text-danger mt-1 ml-2">{errors.address}</div>
//                         )}
//                       </div>
//                       <div className="col-xs-12 form-group mb-3">
//                         <label htmlFor="picker2">TimeLapse</label>
//                         <DateRange
//                           ranges={[{
//                             startDate: new Date(values.timeLapse.startDate),
//                             endDate: new Date(values.timeLapse.endDate),
//                             key: 'selection'
//                           }]}
//                           onChange={handleDateRangeChange}
//                         />
//                       </div>

//                       <div className="col">
//                         <Button type="submit" disabled={isSubmitting}>
//                           {selectedStation ? "Update" : "Submit"}
//                         </Button>
//                       </div>
//                     </div>
//                   </form>
//                 )}
//               </Formik>
//             </Card>
//           </Col>
//         )}

//         <Col md={12}>
//           <Card body>
//             <div className="card-title mb-3">Stations List</div>
//             <Table striped bordered hover>
//               <thead>
//                 <tr>
//                   <th>Manager Name</th>
//                   <th>Station Name</th>
//                   <th>City</th>
//                   <th>Capacity</th>
//                   <th>Time Lapse (days)</th>
//                   <th>Actions</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {stations.map((station) => (
//                   <tr key={station._id}>
//                     <td>{station.managerName}</td>
//                     <td>{station.stationName}</td>
//                     <td>{station.city}</td>
//                     <td>{station.capacity}</td>
//                     <td>{station.timeLapse}</td>
//                     <td>
//                       <Button variant="warning" onClick={() => handleEdit(station)}>Edit</Button>
//                       <Button variant="danger" onClick={() => handleDelete(station._id)}>Delete</Button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </Table>
//           </Card>
//         </Col>
//       </Row>
//     </section>
//   );
// }

// import React, { useState } from 'react';
// import BlogList from './BlogList';
// import BlogForm from './BlogForm';

// export default function BlogManagement() {
//   const [currentView, setCurrentView] = useState('list');
//   const [selectedBlog, setSelectedBlog] = useState(null);

//   const handleAdd = () => {
//     setSelectedBlog(null);
//     setCurrentView('form');
//   };

//   const handleEdit = (blog) => {
//     console.log(blog); // Log the entire blog object to verify what is received
//     setSelectedBlog(blog);
//     setCurrentView('form');
//   };

//   const handleSave = () => {
//     // Assuming you may want a delay before going back to the list
//     setTimeout(() => {
//       setCurrentView('list');
//     }, 4000);
//   };

//   const handleCancel = () => {
//     setCurrentView('list');
//   };

//   return (
//     <div>
//       {currentView === 'list' && (
//         <BlogList onAdd={handleAdd} onEdit={handleEdit} />
//       )}
//       {currentView === 'form' && (
//         <BlogForm blog={selectedBlog} onSave={handleSave} onCancel={handleCancel} />
//       )}
//     </div>
//   );
// }

import React, { useState } from 'react';
import StationList from './StationList';
import StationForm from './StationForm';

export default function StationManagement() {
  const [view, setView] = useState('list');
  const [selectedStation, setSelectedStation] = useState(null);

  const handleAddClick = () => {
    setSelectedStation(null);
    setView('form');
  };

  const handleEditClick = (station) => {
    setSelectedStation(station);
    console.log(selectedStation);
    setView('form');
  };

  const handleSave = () => {
    setView('list');
  };

  const handleCancel = () => {
    setView('list');
  };

  return (
    <div>
      {view === 'list' && (
        <StationList onAdd={handleAddClick} onEdit={handleEditClick} />
      )}
      {view === 'form' && (
        <StationForm station={selectedStation} onSave={handleSave} onCancel={handleCancel} />
      )}
    </div>
  );
}