import * as yup from "yup";
import { Formik } from "formik";
import { useState,useEffect } from "react";
// import { DateRange } from "react-date-range";
import { Button, Row, Col, Card } from "react-bootstrap";
import axios from 'axios';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datetime/css/react-datetime.css';
import BASE_URL from "config";
// import DateTime from 'react-datetime';
// import TimePicker from 'react-time-picker';
const cities = [
  "Casablanca", "Rabat", "Fes", "Marrakech", "Agadir", 
  "Tangier", "Meknes", "Oujda", "Kenitra", "Tetouan","Temara","Safi","El Jadida"
];

const basicFormSchema = yup.object().shape({
  managerName: yup.string().required("Manager name is required"),
  stationName: yup.string().required("Station name is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  city: yup.string().required("City is required"),
  address: yup.string().required("Address is required"),
  capacity: yup.number().min(1, "Capacity must be at least 1").required("Capacity is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  timeLapse:yup.number().min(1, "TimeLapse must be at least 1").required("TimeLapse is required"),
});

export default function StationForm({ station, onSave, onCancel }) {
  const [state, setState] = useState({
    managerName: station?.managerName || "",
    email: station?.email || "",
    phoneNumber: station?.phoneNumber || "",
    stationName: station?.stationName || "",
    city: station?.city || "",
    address: station?.address || "",
    capacity: station?.capacity || "1",
    timeLapse: station?.timeLapse || "1",
  });
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState({});
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/service`);
        setServices(response.data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("Form submitted with values:", values);
    try {
      const requestBody = {
        managerName: values.managerName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        stationName: values.stationName,
        city: values.city,
        address: values.address,
        capacity: Number(values.capacity),
        timeLapse: Number(values.timeLapse),
        services: Object.keys(selectedServices).map(serviceId => ({
          serviceId: serviceId, 
          price: selectedServices[serviceId],
      })),
      };

      console.log("Request body:", requestBody);

      if (station) {
        console.log(`Updating station with ID: ${station._id}`);
        await axios.patch(`${BASE_URL}/api/stations/${station._id}`, requestBody);
        toast.success("Station updated successfully");
      } else {
        console.log("Creating new station");
        await axios.post(`${BASE_URL}/api/stations`, requestBody);
        toast.success("Station added successfully");
      }
      onSave(); 
    } catch (error) {
      console.error("There was an error saving the station!", error.response ? error.response.data : error.message);
      if (error.response && error.response.data && error.response.data.code === 11000) {
        alert("Email already in use. Please choose a different email.");
    } else {
        alert("Failed to save station");
    }
    } finally {
      setSubmitting(false);
    }
  };
  const handleServiceChange = (serviceId, price) => {
    setSelectedServices((prev) => ({
      ...prev,
      [serviceId]: price,
    }));
  };

  return (
    <>

    <Row>
      <Col md={12}>
        <Card body className="mb-4">
          <div className="card-title mb-3">{station ? "Edit Station" : "Add a Station"}</div>
          <Formik
            initialValues={state}
            onSubmit={handleSubmit}
            validationSchema={basicFormSchema}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 form-group mb-3">
                    <label htmlFor="managerName">Manager Name</label>
                    <input
                      id="managerName"
                      name="managerName"
                      className="form-control"
                      placeholder="Enter your name"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.managerName}
                    />
                    {errors.managerName && touched.managerName && (
                      <div className="text-danger mt-1 ml-2">{errors.managerName}</div>
                    )}
                  </div>

                  <div className="col-md-6 form-group mb-3">
                    <label htmlFor="stationName">Station Name</label>
                    <input
                      id="stationName"
                      name="stationName"
                      className="form-control"
                      placeholder="Enter the station's name"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.stationName}
                    />
                    {errors.stationName && touched.stationName && (
                      <div className="text-danger mt-1 ml-2">{errors.stationName}</div>
                    )}
                  </div>

                  <div className="col-md-6 form-group mb-3">
                    <label htmlFor="email">Email address</label>
                    <input
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="exemple@xmail.com"
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <div className="text-danger mt-1 ml-2">{errors.email}</div>
                    )}
                    <small id="emailHelp" className="form-text text-muted">
                      We'll never share your email with anyone else.
                    </small>
                  </div>

                  <div className="col-md-6 form-group mb-3">
                    <label htmlFor="phoneNumber">Phone</label>
                    <input
                      className="form-control"
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="Enter your phone"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                    />
                    {errors.phoneNumber && touched.phoneNumber && (
                      <div className="text-danger mt-1 ml-2">{errors.phoneNumber}</div>
                    )}
                  </div>

                  <div className="col-md-6 form-group mb-3">
                    <label htmlFor="capacity">Capacity of the station</label>
                    <input
                      className="form-control"
                      id="capacity"
                      name="capacity"
                      placeholder="1"
                      type="number"
                      min="1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.capacity}
                    />
                    {errors.capacity && touched.capacity && (
                      <div className="text-danger mt-1 ml-2">{errors.capacity}</div>
                    )}
                  </div>

                  <div className="col-md-6 form-group mb-3">
                    <label htmlFor="city">City</label>
                    <select
                      id="city"
                      className="form-control"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}>
                      <option value="">Select a city</option>
                      {cities.map((city, index) => (
                        <option key={index} value={city}>{city}</option>
                      ))}
                    </select>
                    {errors.city && touched.city && (
                      <div className="text-danger mt-1 ml-2">{errors.city}</div>
                    )}
                  </div>

                  <div className="col-md-6 form-group mb-3">
                    <label htmlFor="address">Address</label>
                    <input
                      className="form-control"
                      id="address"
                      name="address"
                      placeholder="Enter address"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                    />
                    {errors.address && touched.address && (
                      <div className="text-danger mt-1 ml-2">{errors.address}</div>
                    )}
                  </div>

                  <div className="col-md-6 form-group mb-3">
                    <label htmlFor="timeLapse">TimeLapse for washing</label>
                    <input
                      className="form-control"
                      id="timeLapse"
                      name="timeLapse"
                      placeholder="1"
                      type="number"
                      min="1"
                      max="60"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.timeLapse}
                    />
                    {errors.timeLapse && touched.timeLapse && (
                      <div className="text-danger mt-1 ml-2">{errors.timeLapse}</div>
                    )}
                  </div>
                  <br/>
                    <div className="col-md-12 form-group mb-3">
                      <label htmlFor="services">Select Services</label>
                      {services.map((service) => (
                        <div key={service._id} className="d-flex align-items-center mb-2">
                          <input
                            type="checkbox"
                            id={`service-${service._id}`}
                            name="services"
                            value={service._id}
                            onChange={(e) => handleServiceChange(service._id, e.target.checked ? "" : undefined)}
                            style={{ marginRight: '10px' }}
                          />
                          <label htmlFor={`service-${service._id}`} className="me-2">
                            {service.name}
                          </label>
                          {selectedServices[service._id] !== undefined && (
                            <input
                              type="number"
                              placeholder="Enter custom price"
                              value={selectedServices[service._id]}
                              onChange={(e) => handleServiceChange(service._id, e.target.value)}
                              className="ms-2 form-control"
                              style={{ width: '200px' }}
                              required
                            />
                          )}
                        </div>
                      ))}
                    </div>

                  <div>
                    <Button type="submit" disabled={isSubmitting}>
                      {station ? "Update" : "Submit"}
                    </Button> &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="secondary" onClick={onCancel}>Cancel</Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Card>
      </Col>
    </Row>
          <ToastContainer />

    </>
  );
}
