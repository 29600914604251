import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Row, Col, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import Breadcrumb from "app/components/Breadcrumb";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import BASE_URL from 'config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function ReservationList({ onEdit }) {
  const [reservations, setReservations] = useState([]);
  const [stations, setStations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedStation, setSelectedStation] = useState('');
  const [selectedDate, setSelectedDate] = useState(null); 
  const pageSizes = [5, 10, 20];

  useEffect(() => {
    fetchReservations();
    fetchStations();
  }, []);

  const fetchReservations = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/reservations`);
      const sortedReservations = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setReservations(sortedReservations);
    } catch (error) {
      console.error("There was an error fetching the reservations!", error);
      toast.error("Failed to load reservations.");
    }
  };

  const fetchStations = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/stations/all`);
      setStations(response.data);
    } catch (error) {
      console.error("There was an error fetching the stations!", error);
    }
  };

  const handleDelete = async (reservationId) => {
    const isConfirmed = window.confirm("Are you sure you want to cancel this reservation?");
    if (isConfirmed) {
      try {
        await axios.delete(`${BASE_URL}/api/reservations/${reservationId}`);
        toast.success("Reservation canceled successfully");
        fetchReservations(); 
      } catch (error) {
        console.error("There was an error canceling the reservation!", error.response ? error.response.data : error.message);
        toast.error("Failed to cancel reservation");
      }
    }
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); 
  };

  const handleStationSelect = (stationName) => {
    setSelectedStation(stationName);
    setCurrentPage(1); 
  };

  const filteredReservations = reservations
    .filter(reservation => 
      selectedStation ? reservation.station && reservation.station.stationName === selectedStation : true
    )
    .filter(reservation => 
      selectedDate ? new Date(reservation.date).toDateString() === new Date(selectedDate).toDateString() : true
    );

  const totalPages = Math.ceil(filteredReservations.length / pageSize);
  const displayedReservations = filteredReservations.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredReservations.map(reservation => ({
      "Client Name": reservation.name,
      "Email": reservation.email,
      "Phone": reservation.phone,
      "Date": new Date(reservation.date).toLocaleDateString(),
      "Time": reservation.time,
      "Station Name": reservation.station ? reservation.station.stationName : 'Station not found',
      "Pricing Plan": reservation.pricingPlan,
    })));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reservations");
    XLSX.writeFile(wb, "Reservations.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = ["Client Name", "Email", "Phone", "Date", "Time", "Station Name", "Pricing Plan"];
    const tableRows = [];

    filteredReservations.forEach(reservation => {
      const reservationData = [
        reservation.name,
        reservation.email,
        reservation.phone,
        new Date(reservation.date).toLocaleDateString(),
        reservation.time,
        reservation.station ? reservation.station.stationName : 'Station not found',
        reservation.pricingPlan
      ];
      tableRows.push(reservationData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.text("Reservations List", 14, 15);
    doc.save("reservations.pdf");
  };

  return (
    <section>
      <Breadcrumb routeSegments={[{ name: "Reservations", path: "/reservations" }]} />
      <Row>
        <Col md={12} className="mb-3">
          <Button onClick={exportToExcel} className="me-2">Download as Excel</Button>
          <Button onClick={exportToPDF}>Download as PDF</Button>
        </Col>
        <Col md={12}>
          <Card body>
            <div className="card-title mb-3">Reservations List</div>
            <Row className="mb-3">
              <Col>
                <Dropdown onSelect={handlePageSizeChange}>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    Page Size: {pageSize}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {pageSizes.map(size => (
                      <Dropdown.Item key={size} eventKey={size}>{size}</Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col className="text-end">
                <Dropdown onSelect={handleStationSelect}>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {selectedStation ? selectedStation : "Filter by Station"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="">All Stations</Dropdown.Item>
                    {stations.map(station => (
                      <Dropdown.Item key={station._id} eventKey={station.stationName}>{station.stationName}</Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col className="text-end">
                <div className="d-flex align-items-center">
                  <span className="me-2">Filter by Date:</span>
                  <DatePicker
                    selected={selectedDate}
                    onChange={date => setSelectedDate(date)}
                    placeholderText="Select Date"
                  />
                </div>
              </Col>
            </Row>
            <div style={{ overflowX: 'auto' }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Client Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Station Name</th>
                    <th>Pricing Plan</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedReservations.map((reservation) => (
                    <tr key={reservation._id}>
                      <td>{reservation.name}</td>
                      <td>{reservation.email}</td>
                      <td>{reservation.phone}</td>
                      <td>{new Date(reservation.date).toLocaleDateString()}</td>
                      <td>{reservation.time}</td>
                      <td>{reservation.station ? reservation.station.stationName : 'Station not found'}</td>
                      <td>{reservation.pricingPlan}</td>
                      <td>
                        <Button variant="danger" onClick={() => handleDelete(reservation._id)}>Cancel</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Button
                variant="secondary"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <span>Page {currentPage} of {totalPages}</span>
              <Button
                variant="secondary"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </section>
  );
}
