import * as yup from "yup";
import { Formik } from "formik";
import { useState } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from "config";

const registrationFormSchema = yup.object().shape({
  username: yup.string().required("Username is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
});

export default function RegistrationForm({ onSave, onCancel }) {
  const [state, setState] = useState({
    username: "",
    email: "",
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("Form submitted with values:", values);
    try {
      const requestBody = {
        username: values.username,
        email: values.email,
      };

      await axios.post(`${BASE_URL}/api/auth/register`, requestBody);
      toast.success("New admin registered successfully!");
      onSave();
    } catch (error) {
      console.error("There was an error registering the new admin!", error.response ? error.response.data : error.message);

      if (error.response) {
        switch (error.response.status) {
          case 409:
            toast.error("This email is already associated with an existing admin. Please use a different email.");
            break;
          case 400:
            toast.error("The email provided is not valid. Please double-check and try again.");
            break;
          case 401:
            toast.error("This username is already associated with an existing admin. Please use a different one.");
            break;
          default:
            toast.error("An unexpected error occurred. Please try again later.");
            break;
        }
      } else {
        toast.error("Failed to add new admin. Please check your connection and try again.");
      }
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <>
      <Row>
        <Col md={12}>
          <Card body className="mb-4">
            <div className="card-title mb-3">Add New</div>
            <Formik
              initialValues={state}
              onSubmit={handleSubmit}
              validationSchema={registrationFormSchema}>
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 form-group mb-3">
                      <label htmlFor="username">Username</label>
                      <input
                        id="username"
                        name="username"
                        className="form-control"
                        placeholder="Enter your username"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.username}
                      />
                      {errors.username && touched.username && (
                        <div className="text-danger mt-1 ml-2">{errors.username}</div>
                      )}
                    </div>

                    <div className="col-md-6 form-group mb-3">
                      <label htmlFor="email">Email address</label>
                      <input
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="example@mail.com"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && (
                        <div className="text-danger mt-1 ml-2">{errors.email}</div>
                      )}
                    </div>
                    <div className="col-md-12">
                      <Button type="submit" disabled={isSubmitting}>
                        Save
                      </Button> &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button variant="secondary" onClick={onCancel}>Cancel</Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </>
  );
}
