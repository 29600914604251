import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

// import { flat } from "@utils";
// import routes from "app/routes";

import GullLayout from "app/layouts/GullLayout";
import { userLoggedIn } from "app/redux/auth/authSlice";
import localStorageService from "app/services/localStorageService";

// const getUserRoleAuthStatus = (pathname, user, routes) => {
//   if (!user) {
//     return false;
//   }
//   const matched = routes.find((r) => r.path === pathname);

//   const authenticated =
//     matched && matched.auth && matched.auth.length
//       ? matched.auth.includes(user.role)
//       : true;

//   return authenticated;
// };

export default function AuthGuard() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [authenticated, setAuthenticated] = useState(false);
  const [previousRoute, setPreviousRoute] = useState(null);

  const { isAuthenticated } = useSelector((state) => state.auth);

  // const isUserRoleAuthenticated = getUserRoleAuthStatus(
  //   pathname,
  //   user,
  //   flat(routes.routes)
  // );

  // let authenticated = isAuthenticated && isUserRoleAuthenticated;

  // IF YOU NEED ROLE BASED AUTHENTICATION,
  // UNCOMMENT ABOVE TWO LINES, getUserRoleAuthStatus METHOD AND user VARIABLE
  // AND COMMENT OUT BELOW LINE

  // let authenticated = isAuthenticated

  useEffect(() => {
    if (previousRoute !== null) setPreviousRoute(pathname);
  }, [pathname, previousRoute]);

  useEffect(() => {
    const data = localStorageService.getItem("auth_user");

    if (data) {
      dispatch(userLoggedIn({ accessToken: data.accessToken, user: data }));
      setAuthenticated(true);
    } else {
      setAuthenticated(true);
    }
  }, [dispatch, pathname]);

  return (
    authenticated && (
      <>
        {isAuthenticated ? (
          <GullLayout>
            <Outlet />
          </GullLayout>
        ) : (
          <Navigate to="/sessions/signin" replace={true} />
        )}
      </>
    )
  );
}
