import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from 'config';

const supportSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
});

const SupportForm = () => {
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const result = await axios.post(`${BASE_URL}/api/send-support`, {
        name: values.name,
        email: values.email,
        subject: values.subject,
        message: values.message,
      });
      toast.success("Support message sent successfully");
      resetForm();
    } catch (error) {
      toast.error("Failed to send support message: " + (error.response ? error.response.data.message : error.message));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Row>
      <Col md={12}>
        <Card body className="mb-4">
          
          <div className="card-title mb-3">Submit a Support Ticket</div>
          
          <Formik
            initialValues={{ name: '', email: '', subject: '', message: '' }}
            validationSchema={supportSchema}
            onSubmit={handleSubmit}
          >
            {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 form-group mb-3">
                    <label htmlFor="name">Your Name</label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Enter your name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && <div className="text-danger mt-1 ml-2">{errors.name}</div>}
                  </div>

                  <div className="col-md-6 form-group mb-3">
                    <label htmlFor="email">Email Address</label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="form-control"
                      placeholder="Enter your email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email && <div className="text-danger mt-1 ml-2">{errors.email}</div>}
                  </div>

                  <div className="col-md-12 form-group mb-3">
                    <label htmlFor="subject">Subject</label>
                    <input
                      id="subject"
                      name="subject"
                      type="text"
                      className="form-control"
                      placeholder="Subject of your message"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subject}
                    />
                    {errors.subject && touched.subject && <div className="text-danger mt-1 ml-2">{errors.subject}</div>}
                  </div>

                  <div className="col-md-12 form-group mb-3">
                    <label htmlFor="message">Message</label>
                    <textarea
                      id="message"
                      name="message"
                      className="form-control"
                      placeholder="Describe your issue or question"
                      rows="5"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                    />
                    {errors.message && touched.message && <div className="text-danger mt-1 ml-2">{errors.message}</div>}
                  </div>

                  <div className="mt-4">
                    <Button type="submit" disabled={isSubmitting}>
                      Submit
                    </Button>
                    {' '}{' '}{' '}{' '}{' '}
                    <a href={`${process.env.PUBLIC_URL}/supportguide.pdf`} download>
                      <Button variant="warning" className="ml-4">
                        Download Support Guide
                      </Button>
                    </a>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Card>
      </Col>
      <ToastContainer />
    </Row>
  );
};

export default SupportForm;
