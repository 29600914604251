import React, { useEffect, useState } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import BASE_URL from 'config';

function NewsletterList() {
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/newsletter/emails`);
        setEmails(response.data);
      } catch (error) {
        console.error("There was an error fetching the emails!", error);
        toast.error("Failed to load emails.");
      }
    };
    fetchEmails();
  }, []);

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(emails.map(email => ({
      "Email": email.email,
      "Subscribed On": new Date(email.createdAt).toLocaleDateString(),
    })));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Subscribed Emails");
    XLSX.writeFile(wb, "NewsletterEmails.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = ["Email", "Subscribed On"];
    const tableRows = [];

    emails.forEach(email => {
      const emailData = [
        email.email,
        new Date(email.createdAt).toLocaleDateString(),
      ];
      tableRows.push(emailData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.text("Newsletter Emails List", 14, 15);
    doc.save("newsletterEmails.pdf");
  };

  return (
    <section>
      <ToastContainer />
  <div style={{ marginBottom: "20px" }}>
    <Button onClick={exportToExcel} className="me-2">Download as Excel</Button>
    <Button onClick={exportToPDF}>Download as PDF</Button>
  </div>
      <Card body>
        <div className="card-title mb-3">Newsletter Emails</div>
        <div style={{ overflowX: 'auto' }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Email</th>
                <th>Subscribed On</th>
              </tr>
            </thead>
            <tbody>
              {emails.map((email, index) => (
                <tr key={index}>
                  <td>{email.email}</td>
                  <td>{new Date(email.createdAt).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card>
    </section>
  );
}

export default NewsletterList;
