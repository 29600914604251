import React, { useState } from 'react';
import AdminList from './AdminsList';
import AdminForm from './AdminForm';

const AdminManagement = () => {
  const [currentView, setCurrentView] = useState('list');
  const [admin, setAdmin] = useState(null);

  const handleAdd = () => {
    setAdmin(null);
    setCurrentView('form');
  };

  

  const handleSave = () => {
    setCurrentView('list');
  };

  const handleCancel = () => {
    setCurrentView('list');
  };

  return (
    currentView === 'list' ?
      <AdminList onAdd={handleAdd}  /> :
      <AdminForm admin={admin} onSave={handleSave} onCancel={handleCancel} />
  );
};

export default AdminManagement;
