// import React, { useEffect, useState } from 'react';
// import { Button, Card, Table, Row, Col, Modal, Dropdown } from 'react-bootstrap';
// import axios from 'axios';
// import Breadcrumb from "app/components/Breadcrumb";
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import BASE_URL from 'config';
// export default function StationList({ onAdd, onEdit }) {
//   const [stations, setStations] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [stationToDelete, setStationToDelete] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(5);
//   const pageSizes = [5, 10, 20];

//   useEffect(() => {
//     fetchStations();
//   }, []);

//   const fetchStations = async () => {
//     try {
//       const response = await axios.get(`${BASE_URL}/api/stations/all`);
//       setStations(response.data);
//     } catch (error) {
//       console.error("There was an error fetching the stations!", error);
//     }
//   };

//   const handleDelete = async () => {
//     try {
//       await axios.delete(`${BASE_URL}/api/stations/${stationToDelete}`);
//       toast.success("Station deleted successfully");
//       fetchStations();
//     } catch (error) {
//       console.error("There was an error deleting the station!", error.response ? error.response.data : error.message);
//       toast.error("Failed to delete station");
//     } finally {
//       setShowModal(false);
//     }
//   };

//   const openModal = (stationId) => {
//     setStationToDelete(stationId);
//     setShowModal(true);
//   };

//   const handlePageSizeChange = (size) => {
//     setPageSize(size);
//     setCurrentPage(1); 
//   };

//   const totalPages = Math.ceil(stations.length / pageSize);
//   const displayedStations = stations.slice((currentPage - 1) * pageSize, currentPage * pageSize);

//   return (
//     <section>
//       <Breadcrumb routeSegments={[{ name: "Stations", path: "/stations" }]} />
//       <Row>
//         <Col md={12} className="mb-3">
//           <Button onClick={onAdd}>Add Station</Button>
//         </Col>
//         <Col md={12}>
//           <Card body>
//             <div className="card-title mb-3">Stations List</div>
//             <Dropdown onSelect={handlePageSizeChange} className="mb-3">
//               <Dropdown.Toggle variant="secondary" id="dropdown-basic">
//                 Page Size: {pageSize}
//               </Dropdown.Toggle>
//               <Dropdown.Menu>
//                 {pageSizes.map(size => (
//                   <Dropdown.Item key={size} eventKey={size}>{size}</Dropdown.Item>
//                 ))}
//               </Dropdown.Menu>
//             </Dropdown>
//             <div style={{ overflowX: 'auto' }}>
//               <Table striped bordered hover>
//                 <thead>
//                   <tr>
//                     <th>Manager Name</th>
//                     <th>Manager Email</th>
//                     <th>Station Name</th>
//                     <th>City</th>
//                     <th>Capacity</th>
//                     <th>Time Lapse (min)</th>
//                     <th>Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {displayedStations.map((station) => (
//                     <tr key={station._id}>
//                       <td>{station.managerName}</td>
//                       <td>{station.email}</td>
//                       <td>{station.stationName}</td>
//                       <td>{station.city}</td>
//                       <td>{station.capacity}</td>
//                       <td>{station.timeLapse}</td>
//                       <td>
//                         <Button variant="success" onClick={() => onEdit(station)}>Edit</Button> &nbsp;&nbsp;
//                         <Button variant="danger" onClick={() => openModal(station._id)}>Delete</Button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </Table>
//             </div>
//             <div className="d-flex justify-content-between mt-3">
//               <Button
//                 variant="secondary"
//                 onClick={() => setCurrentPage(currentPage - 1)}
//                 disabled={currentPage === 1}
//               >
//                 Previous
//               </Button>
//               <span>Page {currentPage} of {totalPages}</span>
//               <Button
//                 variant="secondary"
//                 onClick={() => setCurrentPage(currentPage + 1)}
//                 disabled={currentPage === totalPages}
//               >
//                 Next
//               </Button>
//             </div>
//           </Card>
//         </Col>
//       </Row>
//       <ToastContainer />

//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Confirm Deletion</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           Are you sure you want to delete this station?
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
//           <Button variant="danger" onClick={handleDelete}>Delete</Button>
//         </Modal.Footer>
//       </Modal>
//     </section>
//   );
// }
// import React, { useEffect, useState } from 'react';
// import { Button, Card, Table, Row, Col, Modal, Dropdown } from 'react-bootstrap';
// import axios from 'axios';
// import Breadcrumb from "app/components/Breadcrumb";
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import BASE_URL from 'config';

// export default function StationList({ onAdd, onEdit }) {
//   const [stations, setStations] = useState([]);
//   const [reservations, setReservations] = useState([]);  
//   const [showModal, setShowModal] = useState(false);
//   const [stationToDelete, setStationToDelete] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(5);
//   const pageSizes = [5, 10, 20];

//   useEffect(() => {
//     fetchStations();
//     fetchReservations(); 
//   }, []);

//   const fetchStations = async () => {
//     try {
//       const response = await axios.get(`${BASE_URL}/api/stations/all`);
//       setStations(response.data);
//     } catch (error) {
//       console.error("There was an error fetching the stations!", error);
//     }
//   };

//   const fetchReservations = async () => {
//     try {
//       const response = await axios.get(`${BASE_URL}/api/reservations`);
//       setReservations(response.data);
//     } catch (error) {
//       console.error("There was an error fetching the reservations!", error);
//     }
//   };

//   const handleDelete = async () => {
//     try {
//       await axios.delete(`${BASE_URL}/api/stations/${stationToDelete}`);
//       toast.success("Station deleted successfully");
//       fetchStations();
//     } catch (error) {
//       console.error("There was an error deleting the station!", error.response ? error.response.data : error.message);
//       toast.error("Failed to delete station");
//     } finally {
//       setShowModal(false);
//     }
//   };

//   const openModal = (stationId) => {
//     setStationToDelete(stationId);
//     setShowModal(true);
//   };

//   const handlePageSizeChange = (size) => {
//     setPageSize(size);
//     setCurrentPage(1); 
//   };

//   // Helper function to calculate the most picked pricing plan for a station
//   const getMostPickedPlan = (stationId) => {
//     // console.log(stationId)
//     // console.log(reservations)
//     const plans = reservations
//       .filter(reservation => reservation.station._id === stationId)
//       .map(reservation => reservation.pricingPlan);

//     if (plans.length === 0) return "No reservations";

//     const planCounts = plans.reduce((acc, plan) => {
//       acc[plan] = (acc[plan] || 0) + 1;
//       return acc;
//     }, {});

//     return Object.keys(planCounts).reduce((a, b) => planCounts[a] > planCounts[b] ? a : b);
//   };

//   const totalPages = Math.ceil(stations.length / pageSize);
//   const displayedStations = stations.slice((currentPage - 1) * pageSize, currentPage * pageSize);

//   return (
//     <section>
//       <Breadcrumb routeSegments={[{ name: "Stations", path: "/stations" }]} />
//       <Row>
//         <Col md={12} className="mb-3">
//           <Button onClick={onAdd}>Add Station</Button>
//         </Col>
//         <Col md={12}>
//           <Card body>
//             <div className="card-title mb-3">Stations List</div>
//             <Dropdown onSelect={handlePageSizeChange} className="mb-3">
//               <Dropdown.Toggle variant="secondary" id="dropdown-basic">
//                 Page Size: {pageSize}
//               </Dropdown.Toggle>
//               <Dropdown.Menu>
//                 {pageSizes.map(size => (
//                   <Dropdown.Item key={size} eventKey={size}>{size}</Dropdown.Item>
//                 ))}
//               </Dropdown.Menu>
//             </Dropdown>
//             <div style={{ overflowX: 'auto' }}>
//               <Table striped bordered hover>
//                 <thead>
//                   <tr>
//                     <th>Manager Name</th>
//                     <th>Manager Email</th>
//                     <th>Station Name</th>
//                     <th>City</th>
//                     <th>Capacity</th>
//                     <th>Time Lapse (min)</th>
//                     <th>Most Picked Plan</th> 
//                     <th>Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {displayedStations.map((station) => (
//                     <tr key={station._id}>
//                       <td>{station.managerName}</td>
//                       <td>{station.email}</td>
//                       <td>{station.stationName}</td>
//                       <td>{station.city}</td>
//                       <td>{station.capacity}</td>
//                       <td>{station.timeLapse}</td>
//                       <td>{getMostPickedPlan(station._id)}</td> 
//                       <td>
//                         <Button variant="success" onClick={() => onEdit(station)}>Edit</Button> &nbsp;&nbsp;
//                         <Button variant="danger" onClick={() => openModal(station._id)}>Delete</Button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </Table>
//             </div>
//             <div className="d-flex justify-content-between mt-3">
//               <Button
//                 variant="secondary"
//                 onClick={() => setCurrentPage(currentPage - 1)}
//                 disabled={currentPage === 1}
//               >
//                 Previous
//               </Button>
//               <span>Page {currentPage} of {totalPages}</span>
//               <Button
//                 variant="secondary"
//                 onClick={() => setCurrentPage(currentPage + 1)}
//                 disabled={currentPage === totalPages}
//               >
//                 Next
//               </Button>
//             </div>
//           </Card>
//         </Col>
//       </Row>
//       <ToastContainer />

//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Confirm Deletion</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           Are you sure you want to delete this station?
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
//           <Button variant="danger" onClick={handleDelete}>Delete</Button>
//         </Modal.Footer>
//       </Modal>
//     </section>
//   );
// }
import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Row, Col, Modal, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import Breadcrumb from "app/components/Breadcrumb";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from 'config';

export default function StationList({ onAdd, onEdit }) {
  const [stations, setStations] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [stationToDelete, setStationToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const pageSizes = [5, 10, 20];

  useEffect(() => {
    fetchStations();
    fetchReservations();
  }, []);

  const fetchStations = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/stations/all`);
      setStations(response.data);
    } catch (error) {
      console.error("There was an error fetching the stations!", error);
    }
  };

  const fetchReservations = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/reservations`);
      setReservations(response.data);
    } catch (error) {
      console.error("There was an error fetching the reservations!", error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${BASE_URL}/api/stations/${stationToDelete}`);
      toast.success("Station deleted successfully");
      fetchStations();
    } catch (error) {
      console.error("There was an error deleting the station!", error.response ? error.response.data : error.message);
      toast.error("Failed to delete station");
    } finally {
      setShowModal(false);
    }
  };

  const openModal = (stationId) => {
    setStationToDelete(stationId);
    setShowModal(true);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  // Helper function to calculate the most picked pricing plan for a station
  const getMostPickedPlan = (stationId) => {
    const plans = reservations
      .filter(reservation => reservation.station._id === stationId)
      .map(reservation => reservation.pricingPlan);

    if (plans.length === 0) return "No reservations";

    const planCounts = plans.reduce((acc, plan) => {
      acc[plan] = (acc[plan] || 0) + 1;
      return acc;
    }, {});

    return Object.keys(planCounts).reduce((a, b) => planCounts[a] > planCounts[b] ? a : b);
  };

  const totalPages = Math.ceil(stations.length / pageSize);
  const displayedStations = stations.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <section>
      <Breadcrumb routeSegments={[{ name: "Stations", path: "/stations" }]} />
      <Row>
        <Col md={12} className="mb-3">
          <Button onClick={onAdd}>Add Station</Button>
        </Col>
        <Col md={12}>
          <Card body>
            <div className="card-title mb-3">Stations List</div>
            <Dropdown onSelect={handlePageSizeChange} className="mb-3">
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                Page Size: {pageSize}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {pageSizes.map(size => (
                  <Dropdown.Item key={size} eventKey={size}>{size}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <div style={{ overflowX: 'auto' }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Manager Name</th>
                    <th>Manager Email</th>
                    <th>Station Name</th>
                    <th>City</th>
                    <th>Capacity</th>
                    <th>Time Lapse (min)</th>
                    <th>Most Picked Plan</th>
                    <th>Services</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedStations.map((station) => (
                    <tr key={station._id}>
                      <td>{station.managerName}</td>
                      <td>{station.email}</td>
                      <td>{station.stationName}</td>
                      <td>{station.city}</td>
                      <td>{station.capacity}</td>
                      <td>{station.timeLapse}</td>
                      <td>{getMostPickedPlan(station._id)}</td>
                      <td>
                        <div style={{ maxHeight: '100px', overflowY: 'auto' }}>
                          {station.services.length === 0 ? (
                            <p>No services available</p>
                          ) : (
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                              {station.services.map((service, index) => (
                                <li key={index}>
                                  {service.serviceId ? (
                                    <>
                                      Service Name: {service.serviceId.name || 'No name available'} - Price: ${service.price || 'No price available'}
                                    </>
                                  ) : (
                                    'Service details unavailable'
                                  )}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </td>

                      <td>
                        <Button variant="success" onClick={() => onEdit(station)}>Edit</Button> &nbsp;&nbsp;
                        <Button variant="danger" onClick={() => openModal(station._id)}>Delete</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Button
                variant="secondary"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <span>Page {currentPage} of {totalPages}</span>
              <Button
                variant="secondary"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      <ToastContainer />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this station?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

