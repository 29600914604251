// import React, { useEffect, useState } from 'react';
// import { Button, Card, Table, Row, Col } from 'react-bootstrap';
// import axios from 'axios';
// import Breadcrumb from "app/components/Breadcrumb";
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import BASE_URL from 'config';

// export default function BlogList({ onAdd, onEdit }) {
//   const [blogs, setBlogs] = useState([]);

//   useEffect(() => {
//     fetchBlogs();
//   }, []);

//   const fetchBlogs = async () => {
//     try {
//       const response = await axios.get(`${BASE_URL}/api/blogs`);
//       setBlogs(response.data);
//     } catch (error) {
//       console.error("There was an error fetching the blogs!", error);
//     }
//   };

//   const handleDelete = async (blogId) => {
//     const isConfirmed = window.confirm("Are you sure you want to delete this blog?");
//     if (isConfirmed) {
//       try {
//         await axios.delete(`${BASE_URL}/api/blogs/${blogId}`);
//         toast.success("Blog deleted successfully");
//         fetchBlogs(); 
//       } catch (error) {
//         console.error("There was an error deleting the blog!", error.response ? error.response.data : error.message);
//         toast.error("Failed to delete blog");
//       }
//     }
//   };

//   // Helper function to format dates
//   const formatDate = (date) => {
//     if (!date) return '';
//     const options = {
//       year: 'numeric',
//       month: 'short',
//       day: 'numeric',
//     };
//     return new Date(date).toLocaleDateString(undefined, options);
//   };

//   return (
//     <section>
//       <Breadcrumb routeSegments={[{ name: "Blogs", path: "/blogs" }]} />
//       <Row>
//         <Col md={12} className="mb-3">
//           <Button onClick={onAdd}>Add Blog</Button>
//         </Col>
//         <Col md={12}>
//           <Card body>
//             <div className="card-title mb-3">Blogs List</div>
//             <div style={{ overflowX: 'auto' }}>

//               <Table striped bordered hover>
//                 <thead>
//                   <tr>
//                     <th>Title</th>
//                     <th>Main Image</th>
//                     {/* <th>Sections</th> */}
//                     {/* <th>Created At</th> */}
//                     <th>publication date</th>
//                     <th>Actions</th>

//                   </tr>
//                 </thead>
//                 <tbody>
//                   {blogs.map((blog) => (
//                     <tr key={blog._id}>
//                       <td>{blog.title}</td>
//                       <td><img src={`${BASE_URL}/api/uploads/${blog.mainImage}`} alt={blog.title} style={{ width: '100px' }} /></td>
//                       {/* <td>
//                         {blog.sections.map((section, index) => (
//                           <div key={index} style={{ marginBottom: '1rem' }}>
//                             <strong>{section.title}</strong>
//                             <p>{section.content}</p>
//                             {index < blog.sections.length - 1 && <hr />} 
//                           </div>
//                         ))}
//                       </td> */}
//                       {/* <td>{formatDate(blog.createdAt)}</td> */}
//                       <td>{formatDate(blog.customUpdatedAt)}</td>
//                       <td>
//                         <Button variant="success" onClick={() => onEdit(blog)}>Edit</Button>
//                         &nbsp;&nbsp;
//                         <Button variant="danger" onClick={() => handleDelete(blog._id)}>Delete</Button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </Table>
//             </div>
//           </Card>
//         </Col>
//       </Row>
//       <ToastContainer />
//     </section>
//   );
// }
import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Row, Col, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import Breadcrumb from "app/components/Breadcrumb";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from 'config';

export default function BlogList({ onAdd, onEdit }) {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const pageSizes = [5, 10, 20];

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/blogs`);
      const sortedBlogs = response.data.sort((a, b) => new Date(b.customUpdatedAt) - new Date(a.customUpdatedAt));
      setBlogs(sortedBlogs);
    } catch (error) {
      console.error("There was an error fetching the blogs!", error);
    }
  };

  const handleDelete = async (blogId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this blog?");
    if (isConfirmed) {
      try {
        await axios.delete(`${BASE_URL}/api/blogs/${blogId}`);
        toast.success("Blog deleted successfully");
        fetchBlogs(); 
      } catch (error) {
        console.error("There was an error deleting the blog!", error.response ? error.response.data : error.message);
        toast.error("Failed to delete blog");
      }
    }
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); 
  };

  const totalPages = Math.ceil(blogs.length / pageSize);
  const displayedBlogs = blogs.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  // Helper function to format dates
  const formatDate = (date) => {
    if (!date) return '';
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  return (
    <section>
      <Breadcrumb routeSegments={[{ name: "Blogs", path: "/blogs" }]} />
      <Row>
        <Col md={12} className="mb-3">
          <Button onClick={onAdd}>Add Blog</Button>
        </Col>
        <Col md={12}>
          <Card body>
            <div className="card-title mb-3 d-flex justify-content-between">
              <Dropdown onSelect={handlePageSizeChange} className="mb-3">
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  Page Size: {pageSize}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {pageSizes.map(size => (
                    <Dropdown.Item key={size} eventKey={size}>{size}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div style={{ overflowX: 'auto' }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Main Image</th>
                    <th>Publication Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedBlogs.map((blog) => (
                    <tr key={blog._id}>
                      <td>{blog.title}</td>
                      <td><img src={`${BASE_URL}/api/uploads/${blog.mainImage}`} alt={blog.title} style={{ width: '100px' }} /></td>
                      <td>{formatDate(blog.customUpdatedAt)}</td>
                      <td>
                        <Button variant="success" onClick={() => onEdit(blog)}>Edit</Button>
                        &nbsp;&nbsp;
                        <Button variant="danger" onClick={() => handleDelete(blog._id)}>Delete</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Button
                variant="secondary"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <span>Page {currentPage} of {totalPages}</span>
              <Button
                variant="secondary"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </section>
  );
}
