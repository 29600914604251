

import React, { useState } from 'react';
import ServiceList from './ServiceList';
import ServiceForm from './ServiceForm';

export default function ServiceManagement() {
  const [view, setView] = useState('list');
  const [selectedService, setSelectedService] = useState(null);

  const handleAddClick = () => {
    setSelectedService(null);
    setView('form');
  };

  const handleEditClick = (service) => {
    setSelectedService(service);
    console.log(selectedService);
    setView('form');
  };

  const handleSave = () => {
    setView('list');
  };

  const handleCancel = () => {
    setView('list');
  };

  return (
    <div>
      {view === 'list' && (
        <ServiceList onAdd={handleAddClick} onEdit={handleEditClick} />
      )}
      {view === 'form' && (
        <ServiceForm service={selectedService} onSave={handleSave} onCancel={handleCancel} />
      )}
    </div>
  );
}